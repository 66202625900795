/* Styles from https://www.figma.com/design/9e7kKlUqz8u3G2tDAyptiD/%E2%9C%85-Highlight-Design-System?node-id=2-2565&t=qKSJfqZ60LeK9brm-0 */

.button {
  padding-top: 4px;
  padding-bottom: 4px;

  /* border-radius: 4px; */
  font-size: 15px;
  font-weight: 600;
  gap: 6px;
  line-height: 20px;
}

.button.solid {
  /* From theme */
}

.button.solid:active {
  background-color: var(--mantine-color-primary-7);
}

.button.solid:disabled,
.button.solid[data-disabled] {
  background-color: var(--mantine-color-gray-3);
  color: var(--mantine-color-white);
}

.button.outline {
  border-width: 1px;

  border-style: solid;
}

.button.outline:hover {
  background-color: var(--mantine-color-primary-0);
}

.button.outline:active {
  background-color: var(--mantine-color-primary-1);
}

.button.outline:disabled,
.button.outline[data-disabled] {
  border-color: var(--mantine-color-gray-3);
  background-color: var(--mantine-color-gray-3);
}

.button.neutral {
  border-width: 1px;

  border-style: solid;
  border-color: var(--mantine-color-gray-4);

  color: var(--mantine-color-gray-8);
}

.button.neutral:hover {
  background-color: var(--mantine-color-gray-1);
}

.button.neutral:active {
  background-color: var(--mantine-color-gray-2);
}

.button.neutral:disabled {
  border-color: var(--mantine-color-gray-3);
  background-color: var(--mantine-color-gray-3);
}

.button.link {
  border-width: 0;
}

.button.link:hover {
  background-color: var(--mantine-color-primary-0);
}

.button.link:active {
  background-color: var(--mantine-color-primary-1);
}

.button.link:disabled,
.button.link[data-disabled] {
  border-width: 0;
  background-color: transparent;
}

.ghost:hover {
  background-color: var(--mantine-color-gray-1);
}

.ghost:active {
  background-color: var(--mantine-color-gray-4);
}

.destructive:disabled {
  background-color: var(--mantine-color-gray-3);
}

.small {
  /* width: 65px; */
  height: 24px;

  padding-right: 8px;
  padding-left: 8px;
}

.medium {
  height: 32px;

  padding-right: 12px;
  padding-left: 12px;
}

.large {
  height: 40px;

  padding-right: 12px;
  padding-left: 12px;
}

.xlarge {
  height: 48px;

  padding-right: 16px;
  padding-left: 16px;
}

span.leftIcon {
  margin-right: 7px;
}

.leftIcon > svg {
  width: 20px;
  height: 20px;
  stroke-width: 2px;
}
